import {
  useUserQuery,
  useVerifyIdentityWithSsnMutation,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import Banner from "@earnnest-e2-frontend/platform-ui/src/Banner"
import Button from "@earnnest-e2-frontend/platform-ui/src/Button"
import FormField from "@earnnest-e2-frontend/platform-ui/src/FormField"
import {
  CircularIcon,
  IdentityIcon,
} from "@earnnest-e2-frontend/platform-ui/src/Icons"
import { Box, Text } from "@earnnest-e2-frontend/platform-ui/src/UI"
import { State } from "country-state-city"
import { Formik } from "formik"
import moment from "moment"
import React, { useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import * as Yup from "yup"

const states = State.getStatesOfCountry("US")

function validationSchema(fullSSN) {
  return Yup.object().shape({
    firstName: Yup.string().required("Legal first name is required."),
    lastName: Yup.string().required("Legal last name is required."),
    ssn: fullSSN
      ? Yup.string()
          .required("Enter your full SSN.")
          .length(11, "Enter your full SSN.")
      : Yup.string()
          .required("Enter the last 4 digits of your SSN.")
          .length(4, "Enter the last 4 digits of your SSN."),
    dateOfBirth: Yup.string()
      .required("Date of birth is required.")
      .test("date", "Must be a valid date (MM/DD/YYYY)", (x) =>
        moment(x, "MM/DD/YYYY", true).isValid(),
      )
      .test(
        ">=18",
        "Must be at least 18",
        (x) => moment().diff(x, "years") >= 18,
      )
      .test(
        "<=125",
        "Enter valid birth date",
        (x) => moment().diff(x, "years") <= 125,
      ),
    line1: Yup.string().required("Street address is required."),
    line2: Yup.string().nullable(),
    city: Yup.string().required("City is required."),
    stateOrRegion: Yup.string()
      .required("State is required.")
      .test(
        "State",
        "Enter a valid state code.",
        (str) => !!states.find((x) => x.isoCode === str.toUpperCase()),
      ),
    postalCode: Yup.string()
      .required("Zip code is required.")
      .test(">=5", "Enter a valid zip code.", (x) => String(x).length >= 5),
  })
}

interface FormProps {
  retry?: boolean
  onSubmit?: Function
  initialValues: {
    firstName: string
    lastName: string
    ssn: string
    dateOfBirth: string
    line1: string
    line2: string
    city: string
    stateOrRegion: string
    postalCode: string
  }
}

export default function SSNIdentityForm({
  initialValues,
  onSubmit,
  retry,
}: FormProps) {
  const { track } = useEarnnestAnalytics()
  const isMobile = useMediaQuery({ maxWidth: 600 })
  const userQuery = useUserQuery()
  const [verifyIdentityWithSsn] = useVerifyIdentityWithSsnMutation()

  console.log(userQuery)

  useEffect(() => {
    track("Verify Identity Viewed", {
      method: retry ? "RETRY" : "LAST4",
    })
  }, [track, retry])

  return (
    <Box>
      <Box pb={48}>
        <Box pb={16} style={{ alignSelf: "center" }}>
          <CircularIcon
            Icon={IdentityIcon}
            size={64}
            iconRatio={0.6}
            backgroundColor="contrast0"
            color="contrast90"
          />
        </Box>
        <Box pb={8}>
          <Text type="heading3" style={{ textAlign: "center" }}>
            {retry ? "Let’s try that again" : "Confirm Identity"}
          </Text>
        </Box>
        <Text type="baseText" style={{ textAlign: "center" }}>
          {retry
            ? "Take this chance to double check your information."
            : "In order to protect you and your payment, we need to confirm your identity."}
        </Text>
      </Box>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={async (values, formik) => {
          try {
            track("Verify Identity Attempted", {
              method: retry ? "RETRY" : "LAST4",
            })
            formik.setSubmitting(true)
            const result = await verifyIdentityWithSsn({
              variables: {
                customerId: userQuery.data?.user.customer?.id || null,
                firstName: values.firstName,
                lastName: values.lastName,
                ssn: values.ssn,
                dateOfBirth: moment(values.dateOfBirth).format("YYYY-MM-DD"),
                address: {
                  line1: values.line1,
                  line2: values.line2,
                  city: values.city,
                  stateOrRegion: String(values.stateOrRegion).toUpperCase(),
                  postalCode: values.postalCode,
                },
              },
            })
            if (
              result.data?.verifyIdentityWithSsn.customer?.status === "RETRY"
            ) {
              formik.setFieldValue("ssn", "")
            }
            track("Verify Identity Succeeded", {
              method: retry ? "RETRY" : "LAST4",
            })
            onSubmit?.(values)
          } catch (error) {
            track("Verify Identity Failed", {
              method: retry ? "RETRY" : "LAST4",
              message: error.message,
            })
            // prettier-ignore
            // Intercept specific Dwolla messages to display something more helpful to user.
            if (error.message === "A customer with the specified email already exists.") {
              formik.setStatus({ serverError: `This identity data has already be used to verify another account. Please contact our support team at support@earnnest.com and we’ll help you reconcile your account.` })
            } else {
              formik.setStatus({ serverError: error.message })
            }
          } finally {
            formik.setSubmitting(false)
          }
        }}
        validationSchema={validationSchema(!!retry)}>
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            style={{ display: "flex", flexDirection: "column" }}>
            <Box pb={24}>
              <Text type="heading4">Identity data</Text>
            </Box>
            <Box
              pb={isMobile ? 0 : 16}
              style={{
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "space-between",
              }}>
              <Box pr={isMobile ? 0 : 16} style={{ flex: 1 }}>
                <FormField name="firstName" label="Legal first name" />
              </Box>
              <Box style={{ flex: 1 }}>
                <FormField name="lastName" label="Legal last name" />
              </Box>
            </Box>
            <Box
              pb={24}
              style={{
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "space-between",
              }}>
              <Box pr={isMobile ? 0 : 16} style={{ flex: 1 }}>
                <FormField
                  name="ssn"
                  keyboardType="numeric"
                  maskType={retry ? "ssn" : "last4ssn"}
                  label={retry ? "Full SSN" : "SSN (last 4)"}
                />
              </Box>
              <Box style={{ flex: 1 }}>
                <FormField
                  name="dateOfBirth"
                  keyboardType="numeric"
                  maskType="date"
                  label="Date of birth"
                  placeholder="MM/DD/YYYY"
                />
              </Box>
            </Box>
            <Box pb={24}>
              <Text type="heading4">Current residence</Text>
            </Box>
            <Box pb={isMobile ? 0 : 16}>
              <FormField name="line1" label="Street address" />
            </Box>
            <Box
              pb={isMobile ? 0 : 16}
              style={{
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "space-between",
              }}>
              <Box pr={isMobile ? 0 : 16} style={{ flex: 1 }}>
                <FormField name="line2" label="Address 2" />
              </Box>
              <Box style={{ flex: 1 }}>
                <FormField name="city" label="City" />
              </Box>
            </Box>
            <Box pb={56} style={{ flexDirection: "row" }}>
              <Box pr={16} style={{ width: 94 }}>
                <FormField
                  name="stateOrRegion"
                  maskType="state"
                  label="State"
                />
              </Box>
              <Box style={{ width: 94 }}>
                <FormField
                  name="postalCode"
                  maskType="zipcode"
                  label="Zip code"
                />
              </Box>
            </Box>
            {props.status?.serverError ? (
              <Box pb={16}>
                <Banner kind="error" message={props.status?.serverError} />
              </Box>
            ) : null}
            <Button
              title={
                props.isSubmitting
                  ? "Submitting..."
                  : retry
                  ? "Retry"
                  : "Confirm"
              }
              size="lg"
              style={{ alignSelf: "center", width: 320 }}
              disabled={
                props.isSubmitting || (!props.isValid && props.submitCount > 0)
              }
              onPress={props.handleSubmit}
            />
            <Box my={12}>
              {retry ? null : (
                <Text type="smallText" color="contrast50" center>
                  By clicking “Confirm” you understand that you are creating a
                  Dwolla account and agree to Dwolla’s{" "}
                  <Text
                    accessibilityRole="link"
                    href="https://www.dwolla.com/legal/tos/"
                    hrefAttrs={{ target: "_blank" }}
                    style={{ textDecorationLine: "underline" }}>
                    Terms of Service
                  </Text>
                  {" and "}
                  <Text
                    accessibilityRole="link"
                    href="https://www.dwolla.com/legal/privacy/"
                    hrefAttrs={{ target: "_blank" }}
                    style={{ textDecorationLine: "underline" }}>
                    Privacy Policy
                  </Text>
                  .
                </Text>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}
