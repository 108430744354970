import {
  FundingSource,
  namedOperations,
  useCreateFundingSourceMutation,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { usePersistedState } from "@earnnest-e2-frontend/platform-api/src/hooks"
import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import { useEarnnestPlaidLink } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestPlaidLink"
import Button from "@earnnest-e2-frontend/platform-ui/src/Button"
import {
  BankIcon,
  CircularIcon,
  IdentityIcon,
} from "@earnnest-e2-frontend/platform-ui/src/Icons"
import LoadingOverlay from "@earnnest-e2-frontend/platform-ui/src/LoadingOverlay/LoadingOverlay"
import { useToast } from "@earnnest-e2-frontend/platform-ui/src/Toast"
import { Box, Text } from "@earnnest-e2-frontend/platform-ui/src/UI"
import { useEffect } from "react"

interface FormProps {
  onVerifyIdentity: () => void
  onSubmitSuccess: (fundingSource: FundingSource) => void
  getPlaidContinueUrl: () => string
}

export default function AddPlaidSourceForm({
  onVerifyIdentity,
  onSubmitSuccess,
  getPlaidContinueUrl,
}: FormProps) {
  const { triggerToast } = useToast()

  const { track } = useEarnnestAnalytics()
  const userQuery = useUserQuery()
  const [
    createFundingSource,
    { loading: plaidSubmitting },
  ] = useCreateFundingSourceMutation()
  const [openPlaidLink] = useEarnnestPlaidLink()

  // persist the current payment path for oauth bank verification
  // used to return here after plaid-continue

  const newPlaidContinueReturnUri = getPlaidContinueUrl()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setPlaidContinueReturnUri] = usePersistedState(
    "plaidContinueReturnUri",
    newPlaidContinueReturnUri,
  )

  useEffect(() => {
    setPlaidContinueReturnUri(newPlaidContinueReturnUri)
    // eslint-disable-next-line
  }, [newPlaidContinueReturnUri])

  useEffect(() => {
    track("Add Funding Source Viewed")
  }, [track])

  if (plaidSubmitting) {
    return <LoadingOverlay title="Updating" />
  }

  if (userQuery.data.user?.customer?.status !== "VERIFIED") {
    return (
      <Box style={{ alignItems: "center" }}>
        <CircularIcon
          Icon={IdentityIcon}
          size={64}
          iconRatio={0.6}
          backgroundColor="contrast0"
          color="contrast90"
        />
        <Box h={16} />
        <Text type="heading3" style={{ textAlign: "center" }}>
          Verify Your Identity
        </Text>
        <Box h={24} />
        <Text type="baseText" style={{ textAlign: "center" }}>
          Before we can connect to your bank account, we need to confirm your
          identity.
        </Text>
        <Box h={32} />
        <Button
          size="md"
          title="Continue"
          onPress={onVerifyIdentity}
          style={{ alignSelf: "center", width: 260 }}
        />
      </Box>
    )
  }

  return (
    // @ts-ignore: No overload error
    <Box>
      <Box pb={24}>
        <Box pb={16} style={{ alignSelf: "center" }}>
          <CircularIcon
            Icon={BankIcon}
            size={64}
            iconRatio={0.6}
            backgroundColor="contrast0"
            color="contrast90"
          />
        </Box>
        <Box pb={8}>
          <Text type="heading3" color="contrast95" center={true}>
            Add a bank account
          </Text>
        </Box>
      </Box>
      <Box pb={64}>
        <Box pb={24}>
          <Text type="heading4" color="contrast95" center={true}>
            Instant verification via Plaid
          </Text>
          <Text type="baseText" color="contrast95" center={true}>
            Plaid is an industry-leading service that instantly verifies your
            bank account without sharing sensitive information with Earnnest.
          </Text>
        </Box>
        {userQuery.data?.user?.customer ? (
          <Button
            title="Use Plaid"
            style={{ width: 260, alignSelf: "center" }}
            onPress={() => {
              track("Add Funding Source Attempted", {
                method: "PLAID",
              })
              openPlaidLink({
                onSuccess: async (token, metadata) => {
                  try {
                    const result = await createFundingSource({
                      variables: {
                        plaidToken: token,
                        name: metadata.account.name,
                        bank: metadata.institution.name,
                        accountId: metadata.account.id,
                        customerRef: userQuery.data.user.customer.customerRef,
                      },
                      refetchQueries: [namedOperations.Query.User],
                    })
                    const fundingSource = result.data
                      ?.createFundingSource as FundingSource
                    track("Add Funding Source Succeeded", {
                      method: "PLAID",
                      status: fundingSource.status,
                    })
                    onSubmitSuccess(fundingSource)
                  } catch (error) {
                    triggerToast({
                      kind: "error",
                      message: error.message,
                    })
                    track("Add Funding Source Failed", {
                      method: "PLAID",
                      message: error.message,
                    })
                  }
                },
                onError: (error) => {
                  triggerToast({
                    kind: "error",
                    message: error.message,
                  })
                },
              })
            }}
          />
        ) : (
          <Button
            disabled
            title="Loading Plaid"
            style={{ width: 260, alignSelf: "center" }}
          />
        )}
      </Box>
    </Box>
  )
}
